exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.22.60\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".HoverTileContentBlock__HoverTile_wrapper{height:100%;position:relative;overflow:hidden;display:flex;min-height:inherit;min-height:275px;min-width:70px}.HoverTileContentBlock__HoverTile_wrapper.HoverTileContentBlock__HoverTile_designer-mode{min-height:27px}.HoverTileContentBlock__HoverTile_wrapper .HoverTileContentBlock__HoverTile_hover-container{position:absolute;display:flex;width:100%;height:100%;left:0;top:0;z-index:1;min-height:inherit}", ""]);

// exports
exports.locals = {
	"wrapper": "HoverTileContentBlock__HoverTile_wrapper",
	"designer-mode": "HoverTileContentBlock__HoverTile_designer-mode",
	"designerMode": "HoverTileContentBlock__HoverTile_designer-mode",
	"hover-container": "HoverTileContentBlock__HoverTile_hover-container",
	"hoverContainer": "HoverTileContentBlock__HoverTile_hover-container"
};